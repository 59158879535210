import React, { useContext, useEffect, useState } from 'react';
import {injectIntl} from "gatsby-plugin-intl"
import { useParams } from '@reach/router';
import * as moment from 'moment';
import LotteriesContext from '../../../state/LotteriesContext';
import './results.scss';
import { transform } from '../../../utils/dateFormatted';
import { getDrawForResults } from '../../../api';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import back from '../../../assets/icons/back.png';
import Select from '../../Forms/Select';
import DrawItem from '../../Cards/DrawItem';
import spinner from '../../../assets/images/spinner.gif';
import Payout from '../../Modals/Payout';
import noTickets from "../../../assets/images/no-tickets.png"


const daysInMonth = (month, year) =>  (new Date(year, month, 0).getDate())

const GameResults = ({setCurretPage, intl: { formatMessage, locale }}) => {
  const [draws, setDraws] = useState([]);
  const [payout, setPayout] = useState(null);
  const [pastResult, setPastResult] = useState(false);
  const { gameSlug } = useParams();
  const { lotteries } = useContext(LotteriesContext);
  const [yearOptions, setYearOptions] = useState([]);
  const [allMonth, setAllMonth] = useState([]);
  const [currentMonthOptions, setCurrentMonthOptions] = useState([]);
  const [currentMonth, setCurrentMonth] = useState({});
  const [currentYear, setCurrentYear] = useState({});
  const [lotteryGame, setLotteryGame] = useState(null);
  const [paramsOptions, setParamsOptions] = useState({});
  const [haveNext, setHaveNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCurretPage('game')
  }, [])

  const currentLocale = locale === 'en' ? '': `/${locale}`
  const translate = (str) => (formatMessage({id: str}))

  useEffect(() => {
    if (gameSlug) {
      const newCurrentGame = lotteries?.find((element) => element.slug === gameSlug);
      if (newCurrentGame) {
        setLotteryGame(newCurrentGame);
        const years = [];
        const year = (new Date()).getFullYear();
        for (let i = 0; i < 5; ++i) {
          years.push({
            value: year - i,
            label: (year - i).toString(),
          });
        }
        setCurrentYear({
          value: year,
          label: year.toString(),
        });
        setYearOptions(years);
        const months = moment.months().map((month) => ({
          value: moment.months().indexOf(month),
          label: translate(month),
        }));

        setAllMonth(months);
        let currentMonthDublicate = null;
        const monthForCurrentYear = months.filter((element) => {
          const curMonth = new Date().getMonth();
          if (element.value === curMonth) {
            setCurrentMonth(element);
            currentMonthDublicate = element;
          }
          return element.value <= curMonth;
        });
        setCurrentMonthOptions(monthForCurrentYear);


        const dateFrom = transform(new Date(year, currentMonthDublicate.value), 'format', 'YYYY/MM/DD');
        const dateTo = transform(new Date(), 'format', 'YYYY/MM/DD');
        getDrawForResults({
          page: 1,
          per_page: 12,
          lottery: newCurrentGame.id,
          dateFrom,
          dateTo,
        }).then((data) => {
          setDraws(data.data.data);
          setPastResult(true);
          setIsLoading(false);
        }).finally(() => {
          setIsLoading(false);
        });
      }

      if(!newCurrentGame && lotteries?.length){
        navigate(`${currentLocale}/results`)
      }
    }
  }, [gameSlug, lotteries]);

  const handleChangeMonth = (value) => {
    if(currentMonth.value === value.value ) {
      return;
    }
    setIsLoading(true);
    const dateFrom = transform(new Date(currentYear.value, value.value), 'format', 'YYYY/MM/DD');
    const dateTo = transform(new Date(currentYear.value, value.value, daysInMonth(value.value + 1, currentYear.value)), 'format', 'YYYY/MM/DD');
    getDrawForResults({
      page: 1,
      per_page: 12,
      lottery: lotteryGame.id,
      dateFrom,
      dateTo,
    }).then((data) => {
      setDraws(data.data.data);
      setPastResult(true);
    }).finally(() => {
      setIsLoading(false);
    });

    setCurrentMonth(value);
  };
  const handleChangeYear = (value) => {
    if(currentYear.value === value.value){
      return;
    }
    if (value.value !== (new Date()).getFullYear()) {
      setCurrentMonthOptions(allMonth);
    } else {
      const months = moment.months().map((month) => ({
        value: moment.months().indexOf(month),
        label: month,
      }));
      const monthForCurrentYear = months.filter((element) => {
        const curMonth = new Date().getMonth();
        if (element.value === curMonth) {
          setCurrentMonth(element);
        }
        return element.value <= curMonth;
      });
      setCurrentMonthOptions(monthForCurrentYear);
    }
    setIsLoading(true);
    const dateFrom = transform(new Date(value.value, currentMonth.value), 'format', 'YYYY/MM/DD');
    const dateTo = transform(new Date(value.value, currentMonth.value, daysInMonth(currentMonth.value + 1, value.value)), 'format', 'YYYY/MM/DD');
    getDrawForResults({
      page: 1,
      per_page: 12,
      lottery: lotteryGame.id,
      dateFrom,
      dateTo,
    }).then((data) => {
      setDraws(data.data.data);
      setPastResult(true);
    }).finally(() => {
      setIsLoading(false);
    });
    setCurrentYear(value);
  };
  const handleShowMore = (event) => {
    getDrawForResults({
      ...paramsOptions,
      page: paramsOptions.page + 1,
    }).then((data) => {
      setDraws([...draws, ...data.data.data]);
      setHaveNext(Boolean(data.data.links.next));
    });
  };
  return (
    <div className="old-results-wrapper">
      <Helmet>
        <title>
          Lotteroo | {translate('Past Results')}
        </title>
      </Helmet>
      <div className="top-block-profile">
        <div className="title">
          {lotteryGame?.lotteryTitle ? <span>   {lotteryGame?.lotteryTitle}
            {' '}
            {translate('Past Results')}</span>: <span
            className="placeholder-data"
            style={{
              height: 40,
              width: 290,
            }}
          />}
        </div>
      </div>
      <div className="submenu page-content">
        <div className="left">
          <Link to={`${currentLocale}/results`}>
            <img src={back} alt="" />
            <span className="back-text">{translate('Back to all results')}</span>
          </Link>
        </div>
        {Boolean(Object.keys(currentMonth).length) &&  <div className="right">
          <Select
            options={currentMonthOptions}
            value={currentMonth}
            onChange={handleChangeMonth}
            inputDisabled
            className="results-select"
          />
          <Select
            options={yearOptions}
            value={currentYear}
            inputDisabled
            onChange={handleChangeYear}
            className="results-select"
          />
        </div>}
      </div>
      <div className="page-content">
        {!isLoading ? (
          <div className="results">
            <ul>
              {draws.length ? draws.map((element) => (
                <li className="result-wrap">
                  <DrawItem
                    key={element.id}
                    setPayout={setPayout}
                    isPast
                    game={element}
                    resultData={{
                      pastResults: true,
                      title: element.lotteryTitle,
                      name: element.slug,
                      drawDate: element.drawDate,
                      mainNumbers: element.mainNumbers,
                      bonusNumbers: element.bonusNumbers,
                      src: element.logoImgUrl,
                      drawNumber: element.drawNumber,
                    }}
                  />
                </li>
              )) : <div className={'not-found'}>
                <div className="empty-results-img">
                  <img src={noTickets} alt="No tickets" />
                </div>
                <div className="empty-results-text">There are no results for this period</div>
              </div>}
            </ul>
          </div>
        ) : (
          <div className="preloader-results-wrapper">
            {' '}
            <img src={spinner} alt="" />
          </div>
        )}
        {haveNext ? <button onClick={handleShowMore}>Show next</button> : null}
        {Boolean(payout) ?  <Payout show={Boolean(payout)} game={payout} onClose={() => setPayout(null)} /> : null }
      </div>
    </div>
  );
};

export default injectIntl(GameResults);
