import React, { useState, memo } from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { transform } from '../../../utils/dateFormatted';

const colors = {
  powerball: {
    6: {
      name: 'powerball',
      color: '#FFF',
      background: '#F00',
    },
  },
  'el-gordo': {
    6: {
      name: 'C',
      color: '#fff',
      background: '#70e43f',
    },
    7: {
      name: 'R',
      color: '#fff',
      background: '#34a9ff',
    },
  },
  'euro-millions': {
    6: {
      name: 'lucky stars',
      color: '#fff',
      background: '#2ebe57',
    },
    7: {
      name: 'lucky stars',
      color: '#fff',
      background: '#ff451a',
    },
  },
  'mega-millions': {
    6: {
      name: 'lucky stars',
      color: '#fff',
      background: '#2ebe57',
    },
    7: {
      name: 'lucky stars',
      color: '#fff',
      background: '#ff451a',
    },
  },
  'euro-jackpot': {
    6: {
      name: 'Euro number',
      color: '#fff',
      position: 6,
      background: '#e3b82d',
    },
    7: {
      name: 'Euro number',
      color: '#fff',
      position: 7,
      background: '#e3b82d',
    },
  },
  '6aus49': {
    6: {
      name: 'super',
      color: '#fff',
      background: '#e3d759',
    },
    7: {
      name: 'superstar',
      color: '#fff',
      background: 'red',
    },
  },
  'super-enalotto': {
    7: {
      name: 'jolly',
      color: '#fff',
      background: '#3ec1ec',
    },
    8: {
      name: 'superstar',
      color: '#fff',
      background: '#e51522',
    },
  },
  'mega-sena': {},
  'la-primitiva': {
    7: {
      name: 'bonus number',
      color: '#fff',
      background: '#fe6f0f',
    },
    8: {
      name: 'refund',
      color: '#fff',
      background: '#3b9c03',
    },
    9: {
      name: 'joker',
      color: '#fff',
      background: '#fe6f0f',
    },
  },
};
const DrawItem = ({
  resultData, setPayout, game, isPast, isHome, intl: { formatMessage, locale },
}) => {
  const [currentInView, setCurrentInView] = useState(false);
  const currentLocale = locale === 'en' ? '': '/' + locale;
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }
  const translate = (str) => (formatMessage({ id: str }));
  return (
    <div className="result-item" ref={ref}>
      {!isPast && (
      <div className="img">
        {resultData.src ? <img src={currentInView ? resultData.src : null} alt="" /> : (
          <span
            className="placeholder-data"
            style={{
              width: '100%',
              height: 40,
            }}
          />
        )}
      </div>
      )}
      <div className="info-numbers-wrap">
        <div className="info">
          {!isPast && (
          <div className="title">
            {resultData.title ? resultData.title : (
              <span
                className="placeholder-data"
                style={{
                  width: '50%',
                  height: 30,
                }}
              />
            )}
          </div>
          )}
          {resultData.pastResults && (
          <div className="date-all">
            <span className={classNames('draw', {
              title: isPast,
            })}
            >
              { resultData.drawNumber ? formatMessage({ id: 'Draw {number}' }, {
                number: resultData.drawNumber,
              }) : translate('Draw on') }
            </span>
            {' '}
            {!isPast && (
            <span>
              -
              {' '}
              <span className="date">{transform(resultData.drawDate, 'format', 'MMMM D, YYYY')}</span>
            </span>
            )}
          </div>
          )}
          {(isPast || isHome) && <div className="date">{transform(resultData.drawDate, 'format', 'D-MM-YYYY')}</div>}
        </div>
        <div className="numbers-rows-wrap">
          <div className="numbers-row">
            <div className="ttl">{translate('Main Numbers')}</div>
            <ul className="numbers picked-numbers main">
              {resultData.mainNumbers ? resultData.mainNumbers.map((element) => (
                <li key={element}>
                  {element}
                </li>
              )) : (
                <>
                  <li />
                  <li />
                  <li />
                </>
              )}
            </ul>
          </div>
          {Boolean(resultData.bonusNumbers?.length) && (
          <div className="numbers-row bonuses">
            <div className="ttl"> {translate('Bonus Numbers')}</div>
            <ul className="numbers picked-numbers">
              {resultData.bonusNumbers?.map((element, index) => (
                <li
                  key={index}
                  style={{
                    background: resultData.name && colors[resultData.name]?.[resultData.mainNumbers.length + index + 1]?.background,
                    color: resultData.name && colors[resultData.name]?.[resultData.mainNumbers.length + index + 1]?.color,
                  }}
                >
                  {element}
                </li>
              ))}
            </ul>
          </div>
          )}
        </div>
      </div>
      {resultData.pastResults && (
        <div className="actions">
          {!isPast && (
          <Link to={`${currentLocale}/results/${game.slug}`}>
            <button className="past-results">
              {translate('Past Results')}
            </button>
          </Link>
          )}
          <br />
          <button className="payouts blue-filled-btn" onClick={() => setPayout(game)}>
             {translate('Payouts')}
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(injectIntl(DrawItem));
