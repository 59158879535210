import React, { useContext, useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import {injectIntl} from "gatsby-plugin-intl"
import { Helmet } from 'react-helmet';
import LotteriesContext from '../../../state/LotteriesContext';
import { getDrawForResults } from '../../../api';
import './results.scss';
// import PageTopMenu from '../../PageTopMenu';
import DrawItem from '../../Cards/DrawItem';
import spinner from '../../../assets/images/spinner.gif';
import Payout from '../../Modals/Payout';
import Loadable from 'react-loadable';

const PageTopMenu = Loadable({
  loader: () => import('../../PageTopMenu'),
  loading: () => <p />,
});

const pages = [
  {
    label: 'All lotteries',
    url: '/results',
  },
  {
    label: 'EuroMillions',
    url: '/results/euro-millions',
  },
  {
    label: 'MegaMillions',
    url: '/results/mega-millions',
  },
];

const AllResults = ({setCurretPage, intl: { formatMessage, locale }}) => {
  const [draws, setDraws] = useState([]);
  const [payout, setPayout] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { gameSlug } = useParams();
  const { lotteries } = useContext(LotteriesContext);

  const currentLocale = locale === 'en' ? '': locale

  const translate = (str) => (formatMessage({id: str}))

  useEffect(() => {
    setCurretPage('all')
  }, [])


  useEffect(() => {
    const paramsObj = {
      page: 1,
      vendorLimit: 1,
      per_page: 12,
    };
    getDrawForResults(paramsObj).then((data) => {
      setDraws(data.data.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [gameSlug, lotteries]);
  return (
    <div className="all-results-page-wrapper">
      <Helmet>
        <title>
          Lotteroo | {translate('Results')}
        </title>
      </Helmet>
      <div className="top-block-profile">
        <div className="title">{translate('Results & Info')}</div>
      </div>

      {(!gameSlug && typeof window !== `undefined`) && <PageTopMenu currentPage="/results" pages={pages} locale={currentLocale}/>}

      <div className="page-content">
        {!isLoading ? (
          <div className="results">
            <ul>
              {draws.map((element) => (
                <li className="result-wrap">
                  <DrawItem
                    key={element.id}
                    setPayout={setPayout}
                    game={element}
                    resultData={{
                      pastResults: true,
                      title: element.lotteryTitle,
                      name: element.slug,
                      drawDate: element.drawDate,
                      mainNumbers: element.mainNumbers,
                      bonusNumbers: element.bonusNumbers,
                      src: element.logoImgUrl,
                      drawNumber: element.drawNumber,
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="preloader-results-wrapper">
            {' '}
            <img src={spinner} alt="" />
          </div>
        )}
        {Boolean(payout) ? <Payout show={Boolean(payout)} game={payout} onClose={() => setPayout(null)} /> : null }
      </div>
    </div>
  );
};

export default injectIntl(AllResults);
