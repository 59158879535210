import React, { useEffect } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import classNames from 'classnames';
import ModalWrapperComponent from '../ModelWrapper';
import style from './payout.module.scss';
import { transform } from '../../../utils/dateFormatted';
import { transformPrice } from '../../../utils/formattes';

const colors = {
  powerball: {
    6: {
      name: 'powerball',
      color: '#FFF',
      background: '#F00',
    },
  },
  'el-gordo': {
    6: {
      name: 'C',
      color: '#fff',
      background: '#70e43f',
    },
    7: {
      name: 'R',
      color: '#fff',
      background: '#34a9ff',
    },
  },
  'euro-millions': {
    6: {
      name: 'lucky stars',
      color: '#fff',
      background: '#2ebe57',
    },
    7: {
      name: 'lucky stars',
      color: '#fff',
      background: '#ff451a',
    },
  },
  'mega-millions': {
    6: {
      name: 'lucky stars',
      color: '#fff',
      background: '#2ebe57',
    },
    7: {
      name: 'lucky stars',
      color: '#fff',
      background: '#ff451a',
    },
  },
  'euro-jackpot': {
    6: {
      name: 'Euro number',
      color: '#fff',
      position: 6,
      background: '#e3b82d',
    },
    7: {
      name: 'Euro number',
      color: '#fff',
      position: 7,
      background: '#e3b82d',
    },
  },
  '6aus49': {
    6: {
      name: 'super',
      color: '#fff',
      background: '#e3d759',
    },
  },
  'super-enalotto': {
    7: {
      name: 'jolly',
      color: '#fff',
      background: '#3ec1ec',
    },
    8: {
      name: 'superstar',
      color: '#fff',
      background: '#e51522',
    },
  },
  'mega-sena': {},
  'la-primitiva': {
    7: {
      name: 'bonus number',
      color: '#fff',
      background: '#fe6f0f',
    },
    8: {
      name: 'refund',
      color: '#fff',
      background: '#3b9c03',
    },
    9: {
      name: 'joker',
      color: '#fff',
      background: '#fe6f0f',
    },
  },
};

const Payout = ({ onClose, game, intl: { formatMessage } }) => {
  useEffect(() => {
    disableBodyScroll(document.querySelector(style.payoutWrapper));
    console.log('lock');
    return () => {
      console.log('unlock');
      clearAllBodyScrollLocks();
    };
  }, []);
  return (
    <ModalWrapperComponent>
      <div className={style.payoutWrapper}>
        <div className={style.payoutOverlay} onClick={onClose} />
        <div className={style.payoutContent}>
          <div className={style.closeBtn} onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
              <path
                fillRule="evenodd"
                fill="#2A84FF"
                d="M13.638 2.109L8.747 7l4.891 4.891a1.236 1.236 0 0 1-1.746 1.747L7 8.748l-4.891 4.89a1.236 1.236 0 0 1-1.747-1.747L5.253 7 .362 2.109A1.235 1.235 0 1 1 2.109.362L7 5.253 11.892.362a1.235 1.235 0 1 1 1.746 1.747z"
              />
            </svg>
          </div>
          <div className={style.payoutHeader}>
            <div className={style.logo}>
              <img src={game.logoImgUrl} alt="" />
            </div>
            <div className={style.titleWrap}>
              <div className={style.title}>{game.lotteryTitle}</div>
              <div className={style.info}>
                <span className={style.draw}>{formatMessage({ id: 'Draw on' })}</span>
                {' '}
                -
                <span className={style.date}>{transform(game.drawDate, 'format', 'MMMM D, YYYY')}</span>
              </div>
            </div>
          </div>

          <div className={style.payoutBody}>
            <div className={style.resultsBlock}>
              <div className={style.numbersRow}>
                <div className={style.ttl}>
                  {formatMessage({ id: 'Main Numbers' })}
                </div>

                <ul className={classNames(style.numbers, style.pickedNumbers, style.main)}>
                  {game.mainNumbers.map((element) => (
                    <li key={element}>
                      {element}
                    </li>
                  ))}
                </ul>
              </div>
              {game.bonusNumbers.length ? (
                <div className={classNames(style.numbersRow, style.bonuses)}>
                  <div className={style.ttl}>
                    {formatMessage({ id: 'Bonus Numbers' })}
                  </div>
                  <ul className={classNames(style.numbers, style.pickedNumbers, style.main)}>
                    {game.bonusNumbers.map((element, index) => (
                      <li
                        key={element}
                        style={{
                          background: game.slug && colors[game.slug]?.[game.mainNumbers.length + index + 1]?.background,
                          color: game.slug && colors[game.slug]?.[game.mainNumbers.length + index + 1]?.color,
                        }}
                      >
                        {element}
                      </li>
                    ))}
                  </ul>

                </div>
              ) : <div className={style.payoutPlaceholder} /> }
            </div>

            {game.payouts.length ? (
              <div className={style.payouts}>
                <table>
                  <tr>
                    <th />
                    <th>{formatMessage({ id: 'Match' })}</th>
                    <th>{formatMessage({ id: 'Payout' })}</th>
                    <th>{formatMessage({ id: 'Winners' })}</th>
                  </tr>

                  {game.payouts.map((element, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{element.match}</td>
                      <td>{element.payout > 0 ? transformPrice(element.payout, 'amountWithCommas') : 'No hits'}</td>
                      <td>
                        {element.winnerCount ? element.winnerCount : null}
                        x
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </ModalWrapperComponent>
  );
};

export default injectIntl(Payout);
