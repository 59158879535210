import React, { useEffect, useState } from "react";
import { createPortal } from 'react-dom';



const ModalWrapperComponent = ({ children }) => {
  const [div, setDiv] = useState(null);
  useEffect(() => {
    const newDiv = document.createElement('div')
    setDiv(newDiv);
    document.body.appendChild(newDiv);
    return () => {
      document.body.removeChild(newDiv);
    };
  }, []);

  if(!div) return null;
  return (
    createPortal(children, div)
  );
};


export default ModalWrapperComponent;
