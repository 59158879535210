import React, { useState } from "react"
import './results.scss';
import { Router } from '@reach/router';
import GameResults from "../../components/pages/Results/GameResults"
import AllResults from "../../components/pages/Results/AllResults"

const Results = (props) => {
  const [currentPage, setCurretPage] = useState(null)
  return   <div className="results-main-wrapper">
    <Router key={currentPage}>
      <AllResults path=":lang/results" key={1} setCurretPage={setCurretPage}/>
      <AllResults path="/results" key={1} setCurretPage={setCurretPage}/>
      <GameResults path="/results/:gameSlug" key={2} setCurretPage={setCurretPage}/>
      <GameResults path=":lang/results/:gameSlug" key={2} setCurretPage={setCurretPage}/>
    </Router>
  </div>
}

export default Results;
